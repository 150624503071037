.card-projetos-detalhes{
	margin-bottom:25px;

	.header-figure{
		position:relative;
		margin-bottom:10px;

		.card-controls{
			position:absolute;
			top:0;
			left:0;
			width:100%;
			height:100%;
			background-color:rgba($theme,0.7);
			display:flex;
			flex-wrap:wrap;
			justify-content:center;
			font-size:27px;
			color:#000;
			align-items:center;

			@include media-breakpoint-up(lg){
				opacity:0;
				transition:opacity 0.6s linear;
			}

			.content{
				position:relative;
			}

		}

	}

	.card-content{
		padding-left:5px;
		padding-right:5px;
		font-family: 'source_sans_probold';

		.title-card{
			font-size:16px;
			color:#111112;
		}

	}

	@include hover-focus{

		.header-figure{

			.card-controls{

				@include media-breakpoint-up(lg){
					opacity:1;
				}

			}

		}

	}

}