.card-projetos{
	margin-bottom:52px;
	max-width:352px;
	margin-left:auto;
	margin-right:auto;

	.figure-img{
		position:relative;

		@include media-breakpoint-up(sm){
			min-height:360px;
			display:flex;
			align-items:flex-end;
			justify-content:center;
		}

		@include media-breakpoint-down(lg){
			background-color:$theme;
		}

		.controls-card{
			padding-top:20px;
			text-align:center;

			@include media-breakpoint-up(xl){
				text-align:left;
				width:100%;
				padding-top:0;
				height:100%;
				display:flex;
				flex-wrap:wrap;
				justify-content:center;
				align-items:flex-end;
				opacity:0;
				transition:opacity 0.6s linear;
				position:absolute;
				top:0;
				left:0;
			}

			&:before{
				content:'';
				display:block;
				width:55px;
				height:63px;
				@extend .icon;
				@extend .icon-plus;
				z-index:20;
				margin-left:auto ;
				margin-right:auto;

				@include media-breakpoint-up(xl){
					position:absolute;
					left:50%;
					transform:translate(-50%,-50%);
					top:calc(50% + 8px);
				}

			}

			.text{
				font-size:15px;
				color:#161616;
				min-height:178px;
				position:relative;
				line-height:1.2;
				overflow:hidden;

				@include media-breakpoint-up(xl){
					padding:44px 20px 15px 20px;
					background-color:rgba($theme,0.7);
				}

				@include media-breakpoint-down(lg){
					padding:20px;
				}

			}

		}

	}

	.content-card{
		word-break:break-all;
		display:flex;
		flex-wrap:wrap;
		justify-content:space-between;
		align-items:center;
		text-align:center;

		.title-card{
			color:#DBDBDB;
			font-family: 'source_sans_probold';
			font-size:16px;
			width:100%;
			padding-bottom:15px;
			padding-top:10px;

			@include media-breakpoint-up(xl){
				text-align:left;
				padding-top:0;
				margin-right:auto;
				max-width:206px;
				padding-bottom:0;
			}

		}

		.bar{
			width:100%;
			height:2px;
			background-color:#FFF;

			@include media-breakpoint-up(xl){
				max-width:115px;
			}

		}

	}

	&:hover{

		.controls-card{
			opacity:1;
		}

	}

	&.text-black{

		.content-card{

			.title-card{
				color:#000;
			}

			.bar{
				background-color:#000;
			}

		}

	}

}