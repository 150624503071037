.form-buscas{
	position:fixed;
	top:-100vh;
	left:0;
	width:100%;
	transition:top 0.5s linear;
	display:flex;
	flex-wrap:wrap;
	justify-content:center;
	align-items:center;
	z-index:1000;

	@include media-breakpoint-down(lg){
		padding-left:20px;
		padding-right:20px;
	}

	form{
		max-width:1110px;
		margin-left:auto;
		margin-right:auto;
		width:100%;

		.input-group{
			position:relative;

			.bar-form{
				position:absolute;
				top:100%;
				left:0;
				width:100%;
				height:4px;
				background-color:#FFF;
				transition:background-color 0.6s ease-in;
			}

			.form-control{
				border:none;
				box-shadow:none;
				border-radius:0;
				font-size:16px;
				font-family: 'source_sans_probold' !important;
				@include place(#FFF);
				background-color:transparent;

				&:focus{

					& ~ .bar-form{
						background-color:$theme;
					}

				}

			}

			.btn{
				box-shadow:none;

				.fa-search{
					color:#FFF;
					font-size:22px;
				}

			}

		}

	}

}