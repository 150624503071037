@font-face {
    font-family: 'open_sansregular';
    src: url('#{$fonts}/opensans-regular/opensans-regular-webfont.eot');
    src: url('#{$fonts}/opensans-regular/opensans-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.woff') format('woff'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/opensans-regular/opensans-regular-webfont.svg#open_sansregular') format('svg');
    font-weight: normal;
    font-style: normal;

}

@font-face {
    font-family: 'source_sans_problack';
    src: url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.eot');
    src: url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.woff2') format('woff2'),
         url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.woff') format('woff'),
         url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.ttf') format('truetype'),
         url('#{$fonts}/SourceSansPro-Black/sourcesanspro-black-webfont.svg#source_sans_problack') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'source_sans_probold';
    src: url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.eot');
    src: url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.woff2') format('woff2'),
         url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.woff') format('woff'),
         url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.ttf') format('truetype'),
         url('#{$fonts}/SourceSansPro-Bold/sourcesanspro-bold-webfont.svg#source_sans_probold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'source_sans_proitalic';
    src: url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.eot');
    src: url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.woff2') format('woff2'),
         url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.woff') format('woff'),
         url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.ttf') format('truetype'),
         url('#{$fonts}/SourceSansPro-Italic/sourcesanspro-italic-webfont.svg#source_sans_proitalic') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'source_sans_proregular';
    src: url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.eot');
    src: url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.woff2') format('woff2'),
         url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.woff') format('woff'),
         url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.ttf') format('truetype'),
         url('#{$fonts}/SourceSansPro-Regular/sourcesanspro-regular-webfont.svg#source_sans_proregular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'work_sansmedium';
    src: url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.eot');
    src: url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.eot?#iefix') format('embedded-opentype'),
         url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.woff2') format('woff2'),
         url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.woff') format('woff'),
         url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.ttf') format('truetype'),
         url('#{$fonts}/WorkSans-Medium/worksans-medium-webfont.svg#work_sansmedium') format('svg');
    font-weight: normal;
    font-style: normal;
}