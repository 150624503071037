.header-section{
	font-size:25px;
	color:#121212;
	margin-bottom:10px;

	.subtitle-section{
		font-size:(20 / 25) * 1em;
		font-family: 'source_sans_problack';
	}

	.title-section{
		font-size:1em;
		line-height:1;
	}

}