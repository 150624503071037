.btn{
	white-space:normal;
	font-size:15px;
	font-family: 'source_sans_probold';
	border-radius:0;

	&.pdg-12-15{
		padding:12px 15px;
	}

	&.btn-gray{
		@include button-variant(#1A1A1C,#1A1A1C);
		box-shadow:0 0 0 0 rgba(#1A1A1C, 0.3);
		color:#FFF;

		@include hover-focus{
			box-shadow:0 0 0 9px rgba(#1A1A1C, 0.3);
		}

	}

	&.btn-theme{
		@include button-variant($theme,$theme);
		color:#FFF;

		@include hover-focus{
			box-shadow:0 0 0 6px rgba(darken($theme,20%), 0.5);
		}

	}

	&.min-width-222{
		min-width:222px;
	}

	&.btn-pill{
		border-radius: 2rem;
	}

}