.card-sobre-nos{
	text-align:center;
	max-width:342px;
	margin-left:auto;
	margin-bottom:25px;
	margin-right:auto;

	.figure-card{
		margin-bottom:8px;
	}

	.content-card{

		.title-card{
			font-size:16px;
			color:#000;
			font-family: 'source_sans_probold';
		}

	}

}