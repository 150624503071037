.card-parceiros{
	margin-bottom:40px;
	max-width:172px;
	padding-top:10px;
	margin-left:auto;
	margin-right:auto;

	.figure-card{
		text-align:center;
		min-height:172px;
		display:flex;
		align-items:center;
		justify-content:center;
		flex-wrap:wrap;
		background-color:#FFF;
		min-width:154px;
		box-shadow:0 0 13px rgba(#666,0.7);
		margin-bottom:0;

		img{
			@include img-fluid();
		}

	}

}