@import "_fontfaces";

body{
	font-size:15px;
	color:#414141;
	font-family: 'source_sans_proregular';
}

#app{
	overflow:hidden;
	position:relative;
}

.topo a,
.rodape a{
	word-break:break-all;
}

.logo{
	margin-bottom:0;
}

a,
.btn{
	transition:all 0.6s linear;
}

a{
	color:inherit;
	text-decoration:none;

	@include hover-focus{
		color:inherit;
		text-decoration:none;
	}

}

*[data-animate]{
	opacity:0;
}

.animated{
	animation-direction:1.4s;
	animation-fill-mode: both;
}

.animated[data-animate]{
	opacity:1;
}

.atraso-1{
	animation-delay:0;
}

.atraso-2{
	animation-delay:0.2s;
}

.atraso-3{
	animation-delay:0.4s;
}

.atraso-4{
	animation-delay:0.6s;
}

lazy-image{
	background-color:transparent;
}

.internas{

	img{
		@include img-fluid();
	}

}

.slick-slider{

	img{
		display:inline-block !important;
	}

	.item{
		outline:none !important;

		a{

			&:focus{
				box-shadow:none;
				outline:none !important;
			}

		}

	}

}

html{
	scroll-behavior:smooth;
}

.text-theme{
	color:$theme !important;
}

.text-gray-mais{
	color:#DBDBDB !important;	
}

.carousel{
	text-align:center;

	.carousel-item{
		position: relative;
		z-index:200;
	}

}

.slick-slider{

	.item{

		img{
			height:auto;
			max-width:100%;
		}

	}

}

:focus{
	outline:none !important;
}

// FONTS USADAS
.opns-reg{
	font-family: 'open_sansregular' !important;
}

.source-pro-black{
	font-family: 'source_sans_problack' !important;
}

.source-pro-bold{
	font-family: 'source_sans_probold' !important;
}

.source-prod-itc{
	font-family: 'source_sans_proitalic' !important;
}

.source-prod-reg{
	font-family: 'source_sans_proregular' !important;
}

.work-med{
	font-family: 'work_sansmedium' !important;
}

.controls-banner{

	& > *{
		font-size:25px;
		@include margin(0,3);
		color:#030303;

		@include hover-focus{
			color:$theme;
		}

	}

	&.controls-banner-white{

		& > *{
			color:#FFF;

			@include hover-focus{
				color:$theme;
			}
			
		}

	}

}

.fnt-16{
	font-size:16px !important;
}

.fnt-25{
	font-size:25px !important;
}