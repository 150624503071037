@import "_home.scss";

.internas{

	.internas-content{
		padding-top:32px;
		padding-bottom:40px;
	}

	&.internas-parceiros{

		.card-parceiros{
			margin-bottom:16px;
		}

	}

	&.internas-projetos{

		.card-projetos{
			margin-bottom:35px;
		}

	}

	&.internas-produtos,
	&.internas-destaques{

		.card-produtos{
			margin-bottom:40px;
		}

	}

}