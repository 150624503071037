.carousel-indicators-custom{
	margin:0;
	padding-bottom:32px;
	
	@include media-breakpoint-up(md){
		padding-bottom:84px;
	}

	& > li{
		min-width:58px;
		height:9px;
		opacity:0.2;
		@include margin(0,5);
	}

}