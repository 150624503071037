.topo{

	@include media-breakpoint-down(md){
		box-shadow:0 0 8px rgba(#000,0.5);
	}

	.bg-menu{
		position:fixed;
		top:0;
		left:0;
		background-color:rgba(#000,0.5);
		opacity:0;
		width:100%;
		transition:opacity 0.6s linear;
	}

	.nav-content{

		@include media-breakpoint-up(lg){
			@include make-container();
			@include make-container-max-widths();
		}

		@include media-breakpoint-down(md){
			position:fixed;
			left:-230px;
			width:230px;
			height:100%;
			background-color:#FFF;
			border-right:1px solid #000;
			overflow:auto;
			z-index:1000;
			transition:left 0.6s linear;
		}

		.first-bar{
			color:#FFF;

			@include media-breakpoint-up(lg){
				padding-top:14px;
				padding-bottom:25px;
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

			.logo-desktop{
				flex-grow:1;
				text-align:center;

				@include media-breakpoint-down(md){
					padding:30px;
					margin-bottom:15px;
				}

			}

			.media-incrivel{

				@include media-breakpoint-down(md){
					display:none;
				}

			}

		}

		.principal-bar{
			font-size:16px;

			@include media-breakpoint-up(lg){
				background-color:#EAEAEA;
				padding-left:29px;
				padding-right:22px;
				display:flex;
				flex-wrap:wrap;
				justify-content:space-between;
				align-items:center;
			}

		}

	}

	&.topo-index{

		@include media-breakpoint-up(lg){
			position:absolute;
			left:0;
			top:0;
			width:100%;
			z-index:300;
		}


	}

	&.topo-internas{
		
		@include media-breakpoint-up(lg){
			background-color:#090909;
		}

		.menu-topo{

			li.dropdown{

				.menu-dropdown{

					&:before{
						border-bottom-color:#EAEAEA;
					}

					& > li{

						& + li{
							border-top-color:darken(#EAEAEA,10%);
						}

						& > a{
							background-color:#EAEAEA;
						}

					}

				}

			}

		}

	}

	&.fx{
		box-shadow:none;

		.mbl-controls{
			position:fixed;
			top:0;
			left:0;
			width:100%;
			background-color:#FFF;
			animation:fadeInDown 0.6s linear;
			z-index:400;
			max-width:100%;
			box-shadow:0 0 8px rgba(#000,0.5);
		}

	}

}

body{

	&.sombra{

		.bg-menu{
			height:100%;
			z-index:800;
			opacity:1;
		}

	}

	&.menu-active{

		.topo{

			.nav-content{
				left:0;
			}

		}

	}

	&.form-pesquisa-topo{

		.form-buscas{
			top:50vh;
			transform:translateY(-50%);
		}

	}

}