.redes-sociais{

	& > *{
		font-size:17px;
		@include margin(0,4);
		width:50px;
		height:60px;
		color:#FFF;
		text-align:center;
		position:relative;
		display:inline-flex;
		align-items:center;
		justify-content:center;
		background-color: #323233;

		@include hover-focus{
			color:#FA9500;
			background-color:$theme;
		}

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			left:0;
			width:100%;
			border-color:transparent;
			border-style:solid;
			border-width:15px;
			border-left-width:25px;
			border-right-width:25px;
			border-right-color:#161617;
			border-left-color:#161617;
		}

		&:before{
			top:0;
			border-top:none;
		}

		&:after{
			bottom:0;
			border-bottom:none;
			
		}

	}

	&.redes-sociais-rodape{

	}

}