.banner{
	text-align:center;

	.carousel-item{
		margin-bottom:0;
	}

}

.texto-max-height{

	@include media-breakpoint-up(lg){
		overflow:auto;
		white-space:normal;

		&.max-height-90{
			max-height:90px;
		}

		&.max-height-170{
			max-height:170px;
		}

	}

}

.quem-somos{
	background-color:$theme;
	text-align:center;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.col-principal{
		padding-top:72px;
		padding-bottom:84px;
		z-index:5;
	}

	figure.especial{
		position:relative;
		margin-bottom:0;
		z-index:4;

		@include media-breakpoint-up(lg){
			min-height:438px;
			min-width:404px;

			&:before{
				content:'';
				display:block;
				position:absolute;
				top:0;
				left:-96px;
				width:600px;
				height:449px;
				z-index:-2;
				background:url('../images/before-quem-somos.png') no-repeat center center;
			}

		}

		img{
			
			@include media-breakpoint-up(lg){
				position:absolute;
				top:0;
				min-height:526px;
				width:100%;
				left:0;
			}

			@include media-breakpoint-down(md){
				@include img-fluid();
			}

		}

	}

}

.parceiros{
	padding-top:45px;
	padding-bottom:20px;

	.slick-slider{

		.slick-track{
			
			& > *{

			}

		}

	}

}

.slick-slider{

	&.padding-items-5{

		.slick-track{

			& > .slick-slide{

				@include media-breakpoint-up(sm){
					margin:0 14px;
				}

			}

		}

	}

}

.projetos{
	background-color:#0D0D0F;
	color:#FFF;
	padding-top:65px;
	text-align:center;
	position:relative;
	z-index:1;

	&:before{
		content:'';
		display:block;
		position:absolute;
		bottom:0;
		left:0;
		width:100%;
		height:20px;
		background-color:#FFF;
		z-index:-4;
	}

}

.produtos{
	padding-top:70px;
	text-align:center;
	padding-bottom:65px;
}

.dual-section{

	.d-xl-flex{

		& > *{
			min-height:344px;
		}

		.parte-um{
			background-repeat:no-repeat;
			background-size:cover;
			background-position:center center;
			background-color:$theme;
			font-family: 'source_sans_probold';
			position:relative;
			z-index:22;

			@include media-breakpoint-down(lg){
				display:flex;
				justify-content:center;
				align-items:center;
				text-align:center;
			}

			@include media-breakpoint-up(xl){
				width:(1042 / 1920)	 * 100%;
				padding-right:128px;
			}

			.content{
				color:#FFF;
				font-size:50px;
				line-height:1;

				@include media-breakpoint-up(xl){
					max-width:464px;
					margin-left:auto;
					margin-right:0;
					padding-top:100px;
					font-size:78px;
				}

			}

		}

		.parte-dois{
			position:relative;
			z-index:4;
			padding-bottom:10px;
			padding-left:40px;
			padding-top:28px;

			@include media-breakpoint-down(lg){
				@include make-container();
				@include make-container-max-widths();
			}

			.link-whats{
				box-shadow:0 0 8px rgba(#000,0.5);
				display:inline-block;
				font-size:14px;
				color:#202020;
				padding:6px 5px 5px 12px;
				background-color:#FFF;
				font-family: 'source_sans_probold';
			}

			&:before{
				content:'';
				display:block;
				background-color:#161617;
				position:absolute;
				bottom:0;
				left:-100vw;
				width:200vw;
				height:37px;
				z-index:-2;
			}

			@include media-breakpoint-up(xl){
				width:(878 / 1920)	 * 100%;
			}

			.content{
				position:relative;
				
				@include media-breakpoint-up(xl){
					max-width:425px;
					margin-right:auto;
				}

				.card-produtos{
					margin-left:0;
					max-width:100%;
				}

				.controls-banner{
					padding-bottom:20px;
					text-align:center;
					
					@include media-breakpoint-up(lg){
						position:absolute;
						text-align:right;
						top:36px;
						right:0;
						padding-bottom:0;
					}

					& > *{
						position:relative;
						z-index:200;
					}

				}

			}

		}

	}

}