.media-incrivel{

	@include media-breakpoint-up(lg){
		display:flex;
		flex-wrap:wrap;
		align-items:center;
	}

	.media-icon{
		margin-bottom:6px;

		@include media-breakpoint-up(lg){
			margin-bottom:0;
			margin-right:10px;
		}

	}

	.media-text{

		.link{

			& + .link{

				&:before{
					content:'|';
					display:inline-block;
					margin:0 3px;
				}

			}

		}

	}

	.bold{
		font-weight:bold;
	}

	&.media-incrivel-contrario{

		@include media-breakpoint-up(lg){
			text-align:right;
		}

		.media-icon{
			margin-bottom:6px;

			@include media-breakpoint-up(lg){
				margin-bottom:0;
				margin-left:10px;
				margin-right:0;
			}

		}

	}

}