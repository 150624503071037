$icons: (
	// bg-position(x,y) width height
	'icon-email': -65px -109px 51px 58px,
	'icon-plus': 0px -109px 55px 63px,
	'icon-poligono': 0px -182px 12px 13px,
	'icon-search': -241px -109px 23px 22px,
	'icon-tel': -126px -109px 50px 58px,
	'icon-whats': -186px -109px 45px 43px,
	'img-sobre-1': 0px 0px 86px 99px,
	'img-sobre-2': -96px 0px 86px 99px,
	'img-sobre-3': -192px 0px 86px 99px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	display: inline-block;
	vertical-align: middle;
	background-size: 278px 195px;

	@each $icon,$value in $icons {
		&.#{$icon} {
			width: nth($value,3);
			height: nth($value,4);
			background-position: #{nth($value,1)} #{nth($value,2)};
		}
	}
}
