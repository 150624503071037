.form-rodape{

	.input-group,
	.form-group{
		margin-bottom:5px;

		.form-control{
			min-height:57px;
			padding-left:25px;
			background-color:#EDEDED;
			@include sombra(#EDEDED);
			resize:none;
			border:none;
			border-radius:0;
		}

	}

}