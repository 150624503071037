.paginacao-custom{

	& > *{
		@include margin(0,2);
	}

	.page-item{

		.page-link{
			border:none;
			padding:0;
			font-size:16px;
			font-family: 'source_sans_problack';
			color:#121212;
			width:40px;
			display:flex;
			flex-wrap:wrap;
			align-items:center;
			justify-content:center;
			height:44px;
			background-color:#DBDBDB;
			border-radius:0;
			position:relative;
			box-shadow:none;

			@include beforeAfter{
				content:'';
				display:block;
				width:100%;
				border:10px solid transparent;
				border-left-width:20px;
				border-right-width:20px;
				border-left-color:#FFF;
				border-right-color:#FFF;
				position:absolute;
				left:0;
			}

			&:before{
				top:0;
				border-top:none;
			}

			&:after{
				bottom:0;
				border-bottom:none;
			}

		}

		&.active > .page-link,
		&:hover  > .page-link{
			background-color:$theme;
		}

	}

}