.menu-topo{
	list-style-type:none;
	padding-left:0;
	margin-bottom:0;
	font-family: 'source_sans_probold';
	color:#111112;

	@include media-breakpoint-up(lg){
		display:flex;
		flex-grow:1;
		flex-wrap:wrap;
		justify-content:space-between;
		max-width:846px;
	}

	@include media-breakpoint-only(lg){
		margin-right:40px;
	}

	& > li{

		&.dropdown{

			& > a{

				@include media-breakpoint-down(md){
					
					&:after{
						content:"\f078";
						display:inline-block;
						font-family: 'Font Awesome 5 Free';
    					font-weight: 900;
    					margin-left:5px;
					}

				}

			}

			.menu-dropdown{
				padding-left:0;
				margin-bottom:0;
				list-style-type:none;
				font-family: 'source_sans_proitalic';

				li{
					display:block;

					& > a{
						display:block;
						background-color:#FFF;

						@include media-breakpoint-down(md){
							background-color:darken(#FFF,10%);
							display:block;
							padding:10px 15px;

							&:before{
								content:'-';
								display:inline-block;
								margin-right:5px;
							}

						}

						@include media-breakpoint-up(lg){
							min-width:177px;
							padding:12px 15px;
						}

					}

					& + li{
						border-top:1px solid #EBEBEB;
					}

					&.active > a,
					&:hover  > a{

						@include media-breakpoint-up(lg){
							color:$theme;
						}

					}

				}

				@include media-breakpoint-up(lg){
					position:absolute;
					top:100%;
					text-align:center;
					left:50%;
					transform:translateX(-50%);
					padding-top:22px;
					overflow:auto;

					&:before{
						content:'';
						display:block;
						position:absolute;
						top:calc(0% + 11px);
						left:calc(50% - 11px);
						transform:translateY(-50%);
						border:11px solid transparent;
						border-bottom-color:#FFF;
					}

				}

				@include media-breakpoint-down(md){
					display:block;

				}

			}

			&:hover{

				.menu-dropdown{

					@include media-breakpoint-up(lg){
						display:block !important;
					}

				}

			}

		}

		& > a{
			display:block;

			@include media-breakpoint-down(md){
				padding:10px 15px;
				border-top:1px solid transparent;
				border-bottom:1px solid transparent;
			}

			@include media-breakpoint-up(lg){
				padding:15px 5px;
				position:relative;

				&:before{
					content:'';
					display:block;
					position:absolute;
					transition:width 0.6s linear;
					bottom:calc(100% - 3px);
					left:0;
					width:0;
					background-color:$theme;
					height:3px;
				}

			}

		}

		&.active > a,
		&:hover  > a{
			color:$theme;

			@include media-breakpoint-down(md){
				background-color:#EAEAEA;
				border-color:darken(#EAEAEA,10%);
			}

			&:before{
				width:100%;
			}

		}

	}

}