.breadcrumb-internas{
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	align-items:center;

	& > *{
		margin-bottom:50px;
	}

	.bar{
		border-bottom:1px solid #191919;
		flex-grow:1;
		max-width:calc(100% - 135px);
		margin-right:25px;
	}

	.texto{
		font-size:16px;
		color:#121212;
		font-family: 'source_sans_problack';

		span{

			& + span{

				&:before{
					content:'|';
					margin:0 8px 0 4px;
				}

			}

		}

	}

}