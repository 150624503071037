.mbl-controls{
	display:flex;
	flex-wrap:wrap;
	justify-content:space-between;
	align-items:center;
	background-color:#FFF;
	z-index:900;
	color:$theme;
	padding:10px 0;
	@include make-container();
	@include make-container-max-widths();

	.logo-mobile{
		max-width:85px;
		padding:6px;
		margin-bottom:0;
	}

	.btn-pesquisas-topo{
		font-size:22px;
		padding:0;
		line-height:1;
		vertical-align:middle;
	}

	.btn-responsivo{
		border:none;
		min-width:auto;
		padding:5px;
		box-shadow:none;
		border-radius:0;

		.bar{
			display:block;
			width:28px;
			height:4px;
			background-color:$theme;
			position:relative;

			@include beforeAfter{
				content:'';
				display:block;
				position:absolute;
				left:0;
				width:100%;
				height:100%;
				background-color:inherit;
			}

			&:before{
				top:8px;
			}

			&:after{
				bottom:8px;
			}

			&,
			&:before,
			&:after{
				transition:all 0.6s linear;
			}

		}

	}

	.parte-login{
		font-size:22px;

		& > *{
			@include margin(0,10);
			display:inline-flex;
			align-items:center;

			@include hover-focus{
				color:$theme;
			}

		}

	}

	.parte-login{


		& > *{
			@include margin(0,5);
		}

	}

}

body.menu-active{


	.btn-responsivo{
		transform:rotate(45deg);

		.bar{

			&:before{
				top:0;
			}

			&:after{
				bottom:0;
			}

			@include beforeAfter{
				transform:rotate(90deg);
			}

		}

	}

}