.rodape{
	position:relative;
	text-align:center;

	@include media-breakpoint-up(lg){
		text-align:left;
	}

	.rodape-contato{
		position:relative;
		color:#979797;

		@include media-breakpoint-up(lg){
			padding-top:38px;
		}

		.container{

			& > .row{

				@include media-breakpoint-down(md){
					background-color:#161617;
					position:relative;
					margin-bottom:20px;
					padding-top:38px;

					@include beforeAfter{
						content:'';
						background-color:inherit;
						display:block;
						position:absolute;
						top:0;
						width:100vw;
						height:100%;
					}

					&:before{
						left:100%;
					}

					&:after{
						right:100%;
					}

				}

			}

		}

		.d-lg-flex{
			box-shadow:0 0 8px rgba(#000,0.5);
		}

		@include media-breakpoint-up(lg){

			&:before{
				content:'';
				display:block;
				background-color:#161617;
				position:absolute;
				top:0;
				left:0;
				width:100%;
				height:424px;
				z-index:-1;
			}

		}

	}

	.box-forms-rodape{
		padding-left:40px;
		padding-right:32px;
		padding-top:32px;
		padding-bottom:32px;
		max-width:778px;
		width:100%;
		background-color:#FFF;
		position:relative;

		@include media-breakpoint-up(lg){

			&:before{
				content:'';
				display:block;
				position:absolute;
				bottom:0;
				left:0;
				width:16px;
				height:278px;
				background-color:$theme;
				box-shadow:0 0 14px rgba(#000,0.8);
			}

		}

		.parte-form{
			width:100%;
		}

		@include media-breakpoint-up(lg){
			display:flex;
			flex-wrap:wrap;
		}

	}

	.box-parte-contato{
		width:100%;
		background-color:$theme;
		box-shadow:0 0 14px rgba(#000,0.4);
		position:relative;

		@include media-breakpoint-up(lg){
			max-width:333px;
		}

		.parte-contato{
			flex-grow:1;
			padding-top:60px;
			padding-bottom:20px;
			padding-left:45px;
			padding-right:50px;
			box-shadow:0 0 14px rgba(#000,0.8);
			text-align:center;
			color:#121212;

			.title{
				font-size:28px;
				font-family: 'source_sans_problack';
				margin-bottom:1em;
			}

			.item-contato{
				text-align:center;
				margin-bottom:5px;

				.icon{
					margin-bottom:5px;
				}

				.subtitle-contato{
					margin-bottom:2px;
				}

			}

			.link{

				& + .link{

					&:before{
						content:'|';
						margin:0 4px;
					}

				}

			}

		}

	}

	.rodape-copyright{
		text-align:center;
		color:#000;
		font-size:10px;
		font-family: 'open_sansregular';
		padding-bottom:22px;

		
		.img-gv8{
			max-width:44px;
		}

	}

}