.card-produtos{
	max-width:260px;
	margin-left:auto;
	margin-right:auto;
	margin-bottom:55px;
	text-align:center;

	@include media-breakpoint-up(xl){
		text-align:left;
	}

	.header-figure{
		position:relative;
		height:96px;
		width:86px;
		background-color:#EEEEEE;
		display:flex;
		flex-wrap:wrap;
		justify-content:center;
		align-items:center;
		margin-bottom:12px;
		transition:background-color 0.6s linear;
		position:relative;

		@include media-breakpoint-down(lg){
			margin-left:auto;
			margin-right:auto;
		}

		img{
			transition:opacity 0.6s linear;
		}

		.controls-card{
			position:absolute;
			top:0;
			left:0;
			display:flex;
			justify-content:center;
			align-items:center;
			width:100%;
			height:100%;
			font-size:23px;
			line-height:1;
			opacity:0;
			color:#FFF;
			transition:opacity 0.6s linear;

			.content{
				max-width:100% !important;
				margin-right:unset !important;
				margin-left:unset !important;
			}

		}

		@include beforeAfter{
			content:'';
			display:block;
			position:absolute;
			left:0;
			width:100%;
			border:25px solid transparent;
			border-left-color:#FFF;
			border-left-width:45px;
			border-right-width:45px;
			border-right-color:#FFF;
			
		}

		&:before{
			top:0;
			border-top:none;
			
		}

		&:after{
			bottom:0;
			border-bottom:none;
		}

	}

	.content-card{

		.title-card{
			font-size:16px;
			font-family: 'source_sans_probold';
			color:#121212;
			margin-bottom:8px;
			transition:color 0.6s linear;
		}

	}

	&:hover{

		.header-figure{
			background-color:$theme;

			img{
				opacity:0;
			}

			.controls-card{
				opacity:1;
			}

		}

		.content-card{

			.title-card{
				color:$theme;
			}

		}

	}

}